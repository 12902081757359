import React, { ReactChildren } from "react";
import { useDispatch } from "react-redux";

import { MOBILE_BREAKPOINT } from "../../globalConstants";
import { goToPage } from "../../redux/app/actions";
import { Page } from "../../types/Config";
import { DataCyStrings } from "../../types/DataCyStrings";
import getTranslator from "../../utility/getTranslator";
import useSelector from "../../utility/useTypedSelector";
import { useViewport } from "../../utility/useViewport";

import { PrivacyFooter } from "../PrivacyFooter";
import PrimaryButton from "../Button/PrimaryButton";
import $FooterBase from "../FooterBase/styles";
import ReturnCountFooter, { DropoffMethodFooter } from "../ReturnCountFooter";
import SVGButton from "../SVGButton";

import $ReturnPortalBase from "./styles";
import ga from "../../utility/GAEmitter";
import {
  AnalyticCategories,
  CommonPageActions,
  getCategoryFromPageOrModalName,
  PreviewPageActions, RefundOptionsPageActions
} from "../../types/Analytics";

const useTranslation = getTranslator("ReturnPortalBase");

export interface ReturnPortalBaseProps {
  page?: Page;
  disableHeader?: boolean;
  children: ReactChildren | JSX.Element;
  footerInfo?: {
    text: string;
    runtime: string;
    count: number;
  };
  footer?: "return-count" | "dropoff-method" | "return-shopping";
  shrinkToFit?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  advance?: Function;
  isFooterDisabledCallback?: () => boolean;
  refundOptionSelected? : string;
}

export const ReturnPortalBase = ({ children, shrinkToFit }: ReturnPortalBaseProps) => {
  return <$ReturnPortalBase shrinkToFit={shrinkToFit} >{children}</$ReturnPortalBase>;
};

export const ReturnPortalBaseWithPage = ({
  page,
  disableHeader,
  children,
  isFooterDisabledCallback,
  footer,
  advance,
  shrinkToFit,
  refundOptionSelected,
}: ReturnPortalBaseProps) => {
  const dispatch = useDispatch();
  const { app, customer } = useSelector((store) => store);
  const {
    currentPageName,
    currentModalPageName
  } = useSelector((store) => store.app);
  const { runtime } = app;
  const { selectedDropoffMethod, itemsMarkedForCompletion } = customer;
  const { t } = useTranslation();

  const { width } = useViewport();

  const backHandler = () => {
    const category = getCategoryFromPageOrModalName(currentPageName, currentModalPageName)
    ga.event({
      category: category,
      action: CommonPageActions.BackArrow
    })
    dispatch(goToPage(page?.leftHeaderLink?.destinationPage));
  };

  let footerInfo;
  if (footer === "return-count" || footer === "return-shopping") {
    footerInfo = {
      count: itemsMarkedForCompletion.length,
      runtime: runtime,
    };
  }
  const disableFooter = !footerInfo || (footerInfo && footerInfo.count <= 0);

  const renderMobilePrivacyFooter = () => {
    if (width >= MOBILE_BREAKPOINT) return null;

    if (footerIsVisible()) {
      return <PrivacyFooter marginTop={"auto"} />;
    }

    // Default to custom offsets defined in PrivacyFooter's stylesheet
    return <PrivacyFooter />;
  };

  const footerIsVisible = (): boolean => {
    return showReturnCountFooter() || showDropoffMethodFooter() || showReturnShoppingFooter();
  };

  const showReturnCountFooter = () => {
    return !disableFooter && footerInfo && footer === "return-count";
  };

  const renderReturnCountFooter = () => {
    if (!showReturnCountFooter()) {
      return null;
    }

    return (
      <div className="footer">
        <ReturnCountFooter {...footerInfo}>
          <PrimaryButton
            label={t("nextStep")}
            onButtonClick={() => advance?.()}
            width="121px"
            disabled={isFooterDisabledCallback?.()}
            dataCyString={DataCyStrings.nextStepButton}
          />
        </ReturnCountFooter>
      </div>
    );
  };

  const showDropoffMethodFooter = () => {
    return footer === "dropoff-method" && selectedDropoffMethod != null;
  };

  const renderDropoffMethodFooter = () => {
    if (!showDropoffMethodFooter()) {
      return null;
    }

    return (
      <div className="footer">
        <DropoffMethodFooter runtime={runtime} dropoffMethod={selectedDropoffMethod}>
          <PrimaryButton
            disabled={isFooterDisabledCallback?.()}
            label={t("submitYourReturn")}
            onButtonClick={() => {
                ga.event({
                  category: AnalyticCategories.PreviewPage,
                  action: PreviewPageActions.SubmitReturn
                })
                advance?.()
              }
            }
            width="160px"
            dataCyString={DataCyStrings.submitReturnButton}
          />
        </DropoffMethodFooter>
      </div>
    );
  };

  const showReturnShoppingFooter = () => {
    return footer === "return-shopping";
  };

  const renderReturnShoppingFooter = () => {
    if (!showReturnShoppingFooter()) {
      return null;
    }

    if (page?.component === "RefundOptions") {
      return (
          <div className="footer return-shopping">
            <ReturnCountFooter {...footerInfo} >
              <div className={isFooterDisabledCallback?.() ? 'activated' : 'deactivated'}>
                <PrimaryButton
                    label={t("nextStep")}
                    onButtonClick={() => {
                      if (refundOptionSelected === "shopOnOurSiteToFind selected") {
                        ga.event({
                          category: AnalyticCategories.RefundOptionsPage,
                          action: RefundOptionsPageActions.ReturnShoppingCheckout
                        });
                      } else {
                        ga.event({
                          category: AnalyticCategories.RefundOptionsPage,
                          action: RefundOptionsPageActions.DropoffMethodPage
                        });
                      }
                      advance?.()
                    }
                    }
                    width="121px"
                    dataCyString={DataCyStrings.nextStepButton}
                />
              </div>
            </ReturnCountFooter>

          </div>
      );
    } else if (page?.component === "preview") {
      return (
          <div className="footer return-shopping">
            <$FooterBase>
              <PrimaryButton
                  disabled={isFooterDisabledCallback?.()}
                  onButtonClick={() => {
                    ga.event({
                      category: AnalyticCategories.PreviewPage,
                      action: PreviewPageActions.ReturnShoppingSubmitReturnGoToCheckout
                    })
                    advance?.()
                  }}
                  width="160px"
                  dataCyString={DataCyStrings.submitReturnButton}
              >
                {t("submitReturnGoTo")}
              </PrimaryButton>
            </$FooterBase>
        </div>
      );
    }
  };

  return (
    <ReturnPortalBase shrinkToFit={shrinkToFit}>
      <div className="page-base">
        {!disableHeader && (
          <div className="page-header">
            <div className="left-header">
              {page?.leftHeaderLink && (
                <SVGButton
                  onClick={backHandler}
                  label={t("goBack")}
                  svg={"backArrow"}
                  dataCyString={DataCyStrings.leftNavigationHeaderButton}
                />
              )}
            </div>
            <div className="middle-header">
              <div className="middle-title" data-cy={DataCyStrings.middleTitle}>
                <h1>{page?.title}</h1>
              </div>
              <div className="middle-subtitle" id={`middle-subtitle-${currentPageName}`}>{page?.subtitle}</div>
            </div>
            <div className="right-header"></div>
          </div>
        )}
        <div className="page-scroll-wrapper">
          <div className="page-content">{children}</div>
          {renderMobilePrivacyFooter()}
        </div>
        {renderDropoffMethodFooter()}
        {renderReturnCountFooter()}
        {renderReturnShoppingFooter()}
      </div>
      {width >= MOBILE_BREAKPOINT && <PrivacyFooter />}
    </ReturnPortalBase>
  );
};

export default ReturnPortalBase;
