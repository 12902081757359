import styled from "styled-components";

import { MOBILE_BREAKPOINT } from "../../../../../globalConstants";

export const $RefundSummary = styled.div`
  margin-top: 16px;
  align-items: left;
  background: var(--primary-white);
  border-radius: 12px;
  border: 1px solid var(--border-card);
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;

  color: #221f1f;
  font-family: "sofia-pro", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;

  svg {
    margin-bottom: 24px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
  }

  .refundSummary, .refundTo {
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 12px;
    font-weight: 800;
  }
`;

export const $Heading = styled.div`
  color: var(--medium-emphasis-black);
  font-family: "sofia-pro", sans-serif;
  font-size: 12px;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: left;
`;

export const $Row = styled.div`
  color: var(--text-black);
  font-family: "sofia-pro", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;

  width: 100%;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .line-through {
    text-decoration: line-through;
  }

  &.first-distribution-row {
    margin-top: 4px;
  }

  &.highlighted {
    background: none;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: var(--text-black);
  }
`;

export const $SubHeading = styled.div`
  color: var(--medium-emphasis-black);
  font-family: "sofia-pro", sans-serif;
  font-size: 11px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: left;
  padding-top: 8px;
  margin-top: 8px;
  border-top: 1px solid var(--border-card);
  width: 100%;
`;

export const $ReturnShoppingInfoMessageContainer = styled.div`
  margin-top: 8px;
  width: 100%;

  svg {
    margin: 0px;
  }

`;
