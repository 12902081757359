import styled from "styled-components";

import { MOBILE_BREAKPOINT } from "../../../globalConstants";

export const $MerchantMessageCard = styled.div`
  background: var(--primary-white);
  border-radius: 12px;
  border: 1px solid var(--border-card);
  height: fit-content;
  padding: 24px;
  width: 100%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    border-bottom: 1px solid var(--border-card);
    border-radius: revert;
    border-top: 1px solid var(--border-card);
  }

  .header {
    color: var(--high-emphasis-black);
    font-weight: 500;
    font-size: 18px;
    line-height: 22.5px;
    display: flex;
    position: relative;
    top: 3.5px;

    h2 {
      color: var(--high-emphasis-black);
      font-weight: 500;
      font-size: 18px;
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }

  .header-mobile {
    color: var(--high-emphasis-black);
    font-weight: 500;
    font-size: 15px;
    line-height: 18.75px;
    display: flex;
    position: relative;
    top: 3.5px;

    h2 {
      color: var(--high-emphasis-black);
      font-weight: 500;
      font-size: 15px;
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }

  .merchant-message {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    display: flex;
    position: relative;
    top: 3.5px;
    margin-top: 8px;
    color: #616161;
    overflow-wrap: anywhere;
  }

  a {
    color: #616161;
    cursor: pointer;
    text-decoration: underline;
  }
`;
