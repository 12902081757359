import React, { ReactNode } from "react";
import { Trans } from "react-i18next";
import { DropoffMethod, ReturnBarDropoffMethod } from "../../pages/DropoffMethods";
import { $ReturnCountFooter } from "./styles";
import getTranslator from "../../utility/getTranslator";
import useSelector from "../../utility/useTypedSelector";
import { DataCyStrings } from "../../types/DataCyStrings";
import { WrapIf } from "../Wrap";
import ItemsPresentCounter from "../ItemsPresentCounter";
import ga from "../../utility/GAEmitter";
import {
  AnalyticCategories,
  AnalyticsPage,
  CommonPageActions,
  ItemListPageActions,
  PageToCategoryMap, PreviewPageActions
} from "../../types/Analytics";

const useTranslation = getTranslator("ReturnCountFooter");
// This component is currently used/shared by the InstanceList,
// Preview, Confirmation, and ScanBags pages
interface ReturnCountFooterProps {
  itemType?: "count-items-present" | "item" | "bag" | "bag-android" | "bag-scan-to-tote",
  count: number,
  itemsPresentCount?: number,
  children: ReactNode,
  runtime: string
}

const ReturnCountFooter = ({ itemType="item", count, itemsPresentCount, children, runtime }: ReturnCountFooterProps) => {
  const { t } = useTranslation();
  const { currentPageName } = useSelector((store) => store.app);
  const getFooterTemplateStr = () => {
    switch (itemType) {
      case "count-items-present":
        return;
      case "bag":
        return count == 1 ? t('bagInReturn') : t('bagsInReturn')
      case "bag-android":
        return count == 1 ? t('bagAdded') : t('bagsAdded')
      case "bag-scan-to-tote":
        return count === 1 ? t("bagAddedToReturn") : t("bagsAddedToReturn")
      default:
        return count == 1 ? t('itemInReturn') : t('itemsInReturn')
    }
  }

  const returnCountClicked = () => {
    if (currentPageName === AnalyticsPage.OrderList) {
      ga.event({
        category: AnalyticCategories.ItemListPage,
        action: ItemListPageActions.ItemCounter
      })
    }
  }
  return <$ReturnCountFooter className={runtime}>
        <WrapIf
          condition={itemType == "count-items-present"}
          wrapIf={(children) => (
            <>
              <ItemsPresentCounter
                itemsSelectedCount={count}
                itemsPresentCount={itemsPresentCount}
              />
              <div className="return-count-group">
                {children}
              </div>
            </>
          )}
          wrapElse={(children) => (
            <>
              <div className="return-count-group">
                <Trans defaults={getFooterTemplateStr()} count={count}>
                <div className="return-count" data-cy={DataCyStrings.returnCount} onClick={returnCountClicked}>{{count}}</div>
                </Trans>
              </div>
              {children}
            </>
          )}
        >{children}</WrapIf>
  </$ReturnCountFooter>
};

interface DropoffMethodFooterProps {
  dropoffMethod: DropoffMethod | ReturnBarDropoffMethod,
  children: any,
  runtime?: string
}

export const DropoffMethodFooter = ({ dropoffMethod, children, runtime }:DropoffMethodFooterProps) => {
  const {t} = useTranslation();
  const {app} = useSelector(store => store);
  const retailerName = app?.copies?.retailerName
  const termsConditionsURL = app?.links?.termsConditionsURL
  const { currentPageName } = useSelector((store) => store.app);

  const onClickSelectedDropoffMethod = () => {
    if (currentPageName === AnalyticsPage.Preview) {
      ga.event({
        category: AnalyticCategories.PreviewPage,
        action: PreviewPageActions.ReturnMethod
      })
    }
  }
  return (
    <$ReturnCountFooter className={`dropoff ${runtime}`}>
      <div className="upper">
        <div className="footer-details">
          <div className="label" data-cy={DataCyStrings.returnMethodFooterLabel}><h2>{t('returnMethod')}</h2></div>
          <div className="dropoff-method" data-cy={DataCyStrings.dropoffMethodText} onClick={onClickSelectedDropoffMethod}>{dropoffMethod?.header}</div>
        </div>
        {children}
      </div>
      {termsConditionsURL && <div className="disclaimer" data-cy={DataCyStrings.termsAndConditionsDisclaimer}>
          <Trans
          t={t}
          values={{retailerName}}
          defaults={retailerName
          ? t('allReturnsAreSubjectToRetailerNames')
          : t('allReturnsAreSubjectToRetailers')}>
            All returns are subject to retailer’s <a href={termsConditionsURL} target="_blank">terms and conditions</a>.
        </Trans>
      </div>}
    </$ReturnCountFooter>
  );
}

export default ReturnCountFooter;
