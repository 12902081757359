import styled from "styled-components";
import { $Button } from "../../components/Button/styles";

const $ReturnStarted = styled.div`
  height: 100%;

  svg[name="checkCircle"] {
    height: 32px;
  }

  svg[name="exclamation"] {
    height: 32px;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    height: auto;
    padding: 16px;
  }

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 480px) {
      margin-top: 12px;
    }
  }
  .express-code {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    font-weight: 800;
    font-size: 18px;
    line-height: 26px;
    color: var(--text-black);
  }
  .next-steps {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    text-align: center;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: var(--text-black);
    white-space: pre-line;
  }
  .qrcode-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
  }
  .apple-pass-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
  }
  .download-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .locations-copy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: var(--medium-emphasis-black);
  }
  .download-link {
    color: #737373;
  }
  .locations-link {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: var(--text-black);
    a:visited {
      color: var(--text-black);
    }
  }
  .mobile-divider-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px 0;

    @media screen and (min-width: 481px) {
      display: none;
    }
  }
  .mobile-divider {
    width: 327px;
    height: 1px;
    background: #cecece;

    @media screen and (min-width: 481px) {
      display: none;
    }
  }
  .footer-container {
    margin-left: -24px;
    margin-right: -24px;
    margin-bottom: -24px;
    padding: 20px;
    text-align: center;

    @media screen and (min-width: 481px) {
      margin-top: 32px;
      background: var(--disabled-grey);
    }
  }
  .custom-retailer-copy {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: var(--header-grey);
  }
  .divider-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;

    @media screen and (max-width: 480px) {
      display: none;
    }
  }
  .divider {
    width: 64px;
    height: 1px;
    background: #cecece;
  }
  .confirmation-email {
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: var(--header-grey);

    @media screen and (min-width: 481px) {
      display: flex;
    }

    @media screen and (max-width: 480px) {
      text-align: center;
    }
  }
  .confirmation-email-spacer {
    padding-left: 3px;
    padding-right: 3px;

    @media screen and (max-width: 480px) {
      display: none;
      margin-top: 4px;
    }
  }
  .confirmation-email-mobile-break {
    display: none;
    @media screen and (max-width: 480px) {
      display: block;
      margin-bottom: 4px;
    }
  }
  .send-email-link {
    cursor: pointer;
    text-decoration: underline;
    color: #737373;
    @media screen and (max-width: 480px) {
      margin-top: 4px;
    }
  }

  .return-bar-next-steps-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: var(--medium-emphasis-black);
    margin-top: 8px;
    order: 1;
    width: 360px;
  }

  .return-expiration-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: var(--medium-emphasis-black);
    margin-top: 8px;
    order: 1;
  }

  .return-shopping-return-expiration-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: var(--medium-emphasis-black);
    order: 1;
  }
  .return-shopping-apple-pass-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    order: 1;
  }
  .return-shopping-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 16px;
    box-sizing: border-box;
    border: 1px solid #dbdbdb;
    border-radius: 12px;
    margin-bottom: 16px;
    align-items: center;
    margin-top: 24px;
    ${$Button} {
      margin-top: 16px;
    }
  }
  .return-shopping-icon {
    height: 20.74px;
    width: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: flex;
    margin-right: 7px;
  }
  .return-shopping-image-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 32px 0px;
    gap: 16px;
    width: 100%;
    height: 112px;
    flex: none;
    order: 0;
    flex-grow: 0;
    @media screen and (max-width: 375px) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  .return-shopping-images {
    object-fit: cover;
    height: 96px;
    width: 96px;
    border: 1px solid var(--border-card);
    border-radius: 12px;
  }
  .more {
    height: min(10vw, 64px);
    width: min(10vw, 64px);
    min-height: 48px;
    min-width: 48px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    white-space: pre-line;
    font-weight: 800;
    font-size: 12px;
    line-height: 18px;
    color: var(--medium-emphasis-black);
  }
  .return-shopping-discount {
    display: flex;
    align-items: center;
    margin-top: 16px;
    font-size: 14px;
    line-height: 10px;
    svg {
      margin-right: 10px;
    }
    padding-left: 32px;
    width: 100%;
    @media screen and (max-width: 375px) {
      padding-left: 0px;
    }
    padding-right: 32px;
  }

  .complete-your-new-order {
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 8px;
    display: flex;
    position: relative;
    top: 3.5px;

    h2{
      font-weight: 700;
      font-size: 18px;
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }
  .return-shopping-cart {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    height: 32px;
    flex: none;
    order: 0;
    flex-grow: 1;
    width: 100%;
  }
  .pay-invoice-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 48px;
    border-radius: 12px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 16px;
    margin-bottom: 8px;
    padding-left: 32px;
    padding-right: 32px;
    @media screen and (max-width: 480px) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  .return-shopping-divider {
    width: 100%;
    height: 1px;
    background: #cecece;
  }

  .return-shopping-return-submitted-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 16px 16px;
    flex: none;
    order: 0;
    flex-grow: 0;
    box-sizing: border-box;
    border: 1px solid #dbdbdb;
    border-radius: 12px;
    margin-bottom: 16px;
    margin-top: 24px;
    ${$Button} {
      margin-top: 16px;
    }
  }
  .return-shopping-return-submitted-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 16px;
    width: 100%;
    height: 32px;
    flex: none;
    order: 0;
    flex-grow: 1;
    margin-bottom: 12px;
  }

  .return-shopping-return-submitted-col1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    width: 183px;
    height: 32px;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #221f1f;
  }
  .return-shopping-icon-container {
    @media screen and (max-width: 480px) {
      margin-top: 12px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 12px;

    width: 25px;
    height: 25px;

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .return-shopping-return-submitted-text {
    width: 147px;
    height: 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;

    color: #221f1f;
    flex: none;
    order: 1;
    flex-grow: 0;
    @media screen and (max-width: 480px) {
      top: 6px;
      position: relative;
    }
  }

  .return-shopping-express-code {
    display: flex;
    height: 18px;
    font-family: "Sofia-Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #737373;
    flex: none;
    order: 1;
    flex-grow: 0;
  }

  .return-shopping-next-steps-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 32px 0px;
    gap: 16px;
    width: 100%;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 12px;
    @media screen and (max-width: 480px) {
      padding: 0;
    }
  }

  .returns-shopping-next-steps {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 100%;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: "Sofia-Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #221f1f;
  }
  .returns-shopping-qrcode-image {
    width: 96px;
    height: 96px;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  .returns-shopping-download-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    order: 1;
    flex: 1;
    margin-bottom: 16px;
  }
  .returns-shopping-locations-copy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 22px;
    gap: 8px;
    font-family: "Sofia-Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #221f1f;
    flex: none;
    order: 3;
    flex-grow: 0;
    margin-top: 16px;
  }
  .returns-shopping-download-link {
    color: #737373;
  }
  .returns-shopping-locations-link {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    height: 18px;
    flex: none;
    order: 3;
    flex-grow: 0;
    margin-top: 8px;
    & a {
      font-family: "Sofia-Pro";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-decoration-line: underline;
      color: #221f1f;
    }
  }

  .returns-shopping-return-code-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    flex: none;
    order: 0;
    flex-grow: 0;
    width: 100%;
  }

  .return-shopping-next-steps-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    flex: none;
    order: 0;
    flex-grow: 0;
    width: 100%;
    color: #737373;
  }
`;

export default $ReturnStarted;
