import styled, { css } from "styled-components";

import { MOBILE_BREAKPOINT } from "../../../../globalConstants";

export const $ReturnDetailsCard = styled.div`
  align-items: left;
  background: var(--primary-white);
  border-radius: 12px;
  border: 1px solid var(--border-card);
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin-bottom: 16px;
  padding: 24px;
  width: 100%;

  color: #221f1f;
  font-family: "sofia-pro", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    border-bottom: 1px solid var(--border-card);
    border-radius: revert;
    border-top: 1px solid var(--border-card);
    width: 100%;
  }

  .itemsInYourReturn, .whatYouAreGetting {
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: 18px;
    font-weight: 500;
  }
`;

export const $GettingDiv = styled.div`
  border-top: 1px solid #dbdbdb;
  margin-top: 24px;
`;

export const $GettingHeader = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
`
export const $HeaderText = styled.div`
  color: var(--text-black);
  font-family: "sofia-pro", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 15px;
  }
`;

export const $ShowMoreLessText = styled.div`
  color: var(--medium-emphasis-black);
  font-family: "sofia-pro", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  text-decoration: underline;

  svg {
    margin: 2px;
  }
`;

export const $InfoMessageContainer = styled.div`
  margin-bottom: 16px;

  svg {
    margin: 0px;
  }

`;

export const $ItemDetailsContainer = styled.div`
  margin-top: 16px;
  align-items: left;
  background: var(--primary-white);
  border-radius: 12px;
  border: 1px solid var(--border-card);
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  gap: 8px;

  color: var(--medium-emphasis-black);
  font-family: "sofia-pro", sans-serif;
  font-size: 12px;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: left;

  .thumbnail {
    width: 64px;
    height: 64px;
    margin-right: 16px;
    flex-shrink: 0;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    position: relative;
    overflow: hidden;

    img {
      width: 64px;
      height: 64px;
      object-fit: contain;
      position: absolute;
      transform: translate(-1px, -1px);
      /* IE11 polyfill */
      font-family: 'object-fit: contain;';
    }
  }

  .gettingExchange{
    font-size: 12px;
    font-weight: 800;
    margin-block-start: 0;
    margin-block-end: 0;
  }
`;

export const $ReturnStatusItemDetails = css`
  margin: 0;
`;


export const $InYourReturnDiv = styled.div``;

export const $InYourReturnHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

