import React from "react";

import { ReturnStatusComposite } from "../index";
import getTranslator from "../../../utility/getTranslator";
import getHtmlFromMarkdown from "../../../utility/getHtmlFromMarkdown"

import { $MerchantMessageCard } from "./styles";
import { isMobile } from "../../../utility";
import DOMPurify from "dompurify";

let MarkdownIt = require('markdown-it');

export const MerchantMessageCard = ({ merchantMessage }: ReturnStatusComposite) => {
  const { t } = getTranslator("MerchantMessageCard")();

  if (merchantMessage) {
    const merchantMessageHtmlString = getHtmlFromMarkdown(merchantMessage)

    return (
      <$MerchantMessageCard>
        <div className={isMobile() ? "header-mobile" : "header"}><h2>{t("header")}</h2></div>
        <div className="merchant-message">
          <SanitizedHTMLRenderer htmlString={merchantMessageHtmlString} />
        </div>
      </$MerchantMessageCard>
    );
  } else {
    return (
      <></>
    );
  }
};

function SanitizedHTMLRenderer({ htmlString }) {
  // Sanitize and render the HTML content
  const sanitizedHTML = DOMPurify.sanitize(htmlString);
  return <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
}
