import styled from "styled-components";

import { MOBILE_BREAKPOINT } from "../../../globalConstants";
import { $Button } from "../../../components/Button/styles";

export const $ReturnShoppingCard = styled.div<{ externalLinkSVGColor?: string }>`
  background: var(--primary-white);
  border-radius: 12px;
  border: 1px solid var(--border-card);
  height: fit-content;
  margin-bottom: 16px;
  padding: 24px;
  width: 100%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    border-bottom: 1px solid var(--border-card);
    border-radius: revert;
    border-top: 1px solid var(--border-card);
    margin-bottom: 8px;
  }

  .return-shopping-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 16px;
    box-sizing: border-box;
    border: 1px solid #dbdbdb;
    border-radius: 12px;
    margin-bottom: 16px;
    align-items: center;
    margin-top: 24px;
    ${$Button} {
      margin-top: 16px;
    }
  }

  .return-shopping-cart {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    height: 24px;
    flex: none;
    order: 0;
    flex-grow: 1;
    width: 100%;
    margin-bottom: 16px;
  }

  .return-shopping-icon {
    height: 20.74px;
    width: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: flex;
  }

  .complete-your-new-order {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    position: relative;
    top: 3.5px;

    h2 {
      font-weight: 500;
      font-size: 18px;
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }

  .complete-your-new-order-mobile {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    position: relative;
    top: 3.5px;

    h2 {
      font-weight: 500;
      font-size: 15px;
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }

  .return-shopping-divider {
    width: 100%;
    height: 1px;
    background: #cecece;
  }

  .return-shopping-image-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 56px;
    flex: none;
    order: 0;
    flex-grow: 0;
    @media screen and (max-width: 375px) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .more {
    height: min(10vw, 64px);
    width: min(10vw, 64px);
    min-height: 48px;
    min-width: 48px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    white-space: pre-line;
    font-weight: 800;
    font-size: 12px;
    line-height: 18px;
    color: var(--medium-emphasis-black);
  }

  .return-shopping-processing-fee {
    display: flex;
    align-items: center;
    margin-top: 16px;
    font-size: 15px;
    line-height: 20px;
    svg {
      margin-right: 10px;
    }
    width: 100%;
    @media screen and (max-width: 375px) {
      padding-left: 0px;
    }
    padding-right: 32px;
    color: #616161;
  }

  .return-shopping-discount {
    display: flex;
    align-items: center;
    margin-top: 16px;
    font-size: 15px;
    line-height: 20px;
    svg {
      margin-right: 10px;
    }
    width: 100%;
    @media screen and (max-width: 375px) {
      padding-left: 0px;
    }
    padding-right: 32px;
    color: #616161;
  }

  .return-shopping-discount-margin-top-8 {
    display: flex;
    align-items: center;
    margin-top: 8px;
    font-size: 15px;
    line-height: 20px;
    svg {
      margin-right: 10px;
    }
    width: 100%;
    @media screen and (max-width: 375px) {
      padding-left: 0px;
    }
    padding-right: 32px;
    color: #616161;
  }

  .pay-invoice-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;
    width: fit-content !important;
    border-radius: 12px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 16px;
    margin-bottom: 8px;

    svg {
      width: 12px;
      height: 12px;
      margin-bottom: 4px;
      path {
        fill: ${(props) => (props.externalLinkSVGColor ? props.externalLinkSVGColor : "var(--primary-white)")};
      }
    }
  }

  .pay-invoice-button:hover {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;
    width: fit-content !important;
    border-radius: 12px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 16px;
    margin-bottom: 8px;

    svg {
      width: 12px;
      height: 12px;
      margin-bottom: 4px;
      path {
        fill: var(--primary-white);
      }
    }
  }

  .pay-invoice-button-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;
    width: 100%;
    border-radius: 12px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 16px;
    margin-bottom: 8px;

    svg {
      width: 12px;
      height: 12px;
      margin-bottom: 4px;
      path {
        fill: ${(props) => (props.externalLinkSVGColor ? props.externalLinkSVGColor : "var(--primary-white)")};
      }
    }
  }

  .return-shopping-images {
    object-fit: cover;
    height: 56px;
    width: 56px;
    border: 1px solid var(--border-card);
    border-radius: 8px;
  }

  .call-to-action-button-text {
    margin-right: 8px;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
  }

  .call-to-action-button-contents {
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
  }
`;
