import styled from "styled-components";

export const $TimeoutModalContents = styled.div`
  width: 616px;
  border-radius: 12px;
  padding: 32px;
  background: white;

  @media (max-width: 758px) {
    width: 100%;
  }
  svg[name="exclamation"] {
    height: 32px;
    margin: auto;
    display: block;
  }

  .header {
    margin-top: 16px;
    font-size: 18px;
    font-weight: 800;
    text-align: center;
    line-height: 26px;
    color: var(--text-black);

    h1 {
      margin-top: 16px;
      font-size: 18px;
      font-weight: 800;
      text-align: center;
      line-height: 26px;
      color: var(--text-black);
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }
  .message {
    margin-top:24px;
    font-size: 15px;
    line-height: 22px;
    color: var(--header-grey);
    margin-top: 8px;
    text-align: center;
  }
  button {
    margin: auto;
    display: block;
    margin-top: 24px;
    padding: 16px;
    height: auto;
  }
`;

export default $TimeoutModalContents
