import styled from "styled-components";

// language=SCSS prefix=*{ suffix=}
const $PageModal = styled.div.attrs(props => ({
  noHeader: props.noHeader || false,
}))`
  width: 100%;
  .modal-header {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    min-height: 64px;
    padding: 10px;
    text-align: center;
    ${props => props.noHeader ? "" : "border-bottom: 1px solid var(--border-grey);"};

    .right {
      justify-self: flex-end;
    }

    .left {
      min-width: 32px;
    }

    .page-title h1{
      font-size: 16px;
      font-weight: 400;
      margin-block-start: 0;
      margin-block-end: 0;
    }

  }
  @media (max-width: 768px) {
    .modal-header {
      min-height: 48px;
      margin: 0 -15px;
    }
  }
`;

export default $PageModal;
