import styled from "styled-components";
import $FooterBase from "../FooterBase/styles";

const $ReturnCountFooter = styled<any>($FooterBase)`
  button {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 15px !important;
    margin-left: 8px;
  }
  .return-count-group {
    display: inline-flex;
    align-items: center;
    span {
      color: #737373;
      font-size: 15px;
      line-height: 15.03px;
    }
  }
  .return-count {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f1f1f1;
    border-radius: 100px;
    padding-top: 4px;
    margin-right: 12px;
    height: 32px;
    width: 32px;
    color: #221f1f;
    font-size: 16px;
    font-weight: 800;
    line-height: 16.02px;
  }
  .footer-details {
    display: flex;
    flex-direction: column;
    .label {
      font-weight: 800;
      font-size: 11px;
      line-height: 16px;
      color: var(--header-grey);

      h2 {
        font-weight: 800;
        font-size: 11px;
        line-height: 16px;
        color: var(--header-grey);
        margin-block-start: 0;
        margin-block-end: 0;
      }
    }
    .dropoff-method {
      font-size: 14px;
      line-height: 20px;
    }
    @media (max-width: 768px) {
      .label {
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.5px;
      }
      .dropoff-method {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  .android & {
    bottom: 0;
    box-shadow: none;
    border-top: 1px solid var(--border-grey);
  }
`;

export { $ReturnCountFooter };
