import React from "react";

const PrinterFree = (props) => (
  <>
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M25.8337 13.9998C25.8337 20.5352 20.5357 25.8332 14.0003 25.8332C11.1259 25.8332 8.49089 24.8083 6.4407 23.1041L9.07697 20.4678C9.22768 20.8292 9.58434 21.0832 10.0003 21.0832H18.0003C18.5526 21.0832 19.0003 20.6355 19.0003 20.0832V18.4443H20.3337C21.4382 18.4443 22.3337 17.5488 22.3337 16.4443V11.5554C22.3337 10.4508 21.4382 9.55539 20.3337 9.55539H19.9894L23.1046 6.44021C24.8088 8.4904 25.8337 11.1254 25.8337 13.9998ZM10.2503 19.8332V19.2944L13.8783 15.6665H17.7503V19.8332H10.2503ZM10.2503 17.5267L12.1105 15.6665H10.2503V17.5267ZM18.0003 14.4165H15.1283L18.4894 11.0554H20.3337C20.6098 11.0554 20.8337 11.2792 20.8337 11.5554V16.4443C20.8337 16.7204 20.6098 16.9443 20.3337 16.9443H19.0003V15.4165C19.0003 14.8642 18.5526 14.4165 18.0003 14.4165ZM13.3605 14.4165L16.7216 11.0554H7.66699C7.39085 11.0554 7.16699 11.2792 7.16699 11.5554V16.4443C7.16699 16.7204 7.39085 16.9443 7.66699 16.9443H9.00033V15.4165C9.00033 14.8642 9.44804 14.4165 10.0003 14.4165H13.3605ZM19.0004 8.61095V8.77664L22.2554 5.52157C20.1235 3.44538 17.2113 2.1665 14.0003 2.1665C7.46496 2.1665 2.16699 7.46447 2.16699 13.9998C2.16699 17.2108 3.44587 20.123 5.52205 22.2549L9.00033 18.7767V18.4443H7.66699C6.56242 18.4443 5.66699 17.5489 5.66699 16.4443V11.5554C5.66699 10.4508 6.56242 9.55539 7.66699 9.55539H9.00036V8.61095V7.6665C9.00036 6.56193 9.89579 5.6665 11.0004 5.6665H17.0004C18.1049 5.6665 19.0004 6.56193 19.0004 7.6665V8.61095ZM27.3337 13.9998C27.3337 21.3636 21.3641 27.3332 14.0003 27.3332C6.63653 27.3332 0.666992 21.3636 0.666992 13.9998C0.666992 6.63604 6.63653 0.666504 14.0003 0.666504C21.3641 0.666504 27.3337 6.63604 27.3337 13.9998ZM11.0004 7.1665H17.0004C17.2765 7.1665 17.5004 7.39036 17.5004 7.6665V8.61095H10.5004V7.6665C10.5004 7.39036 10.7242 7.1665 11.0004 7.1665ZM18.792 13.5832C19.1372 13.5832 19.417 13.3033 19.417 12.9582C19.417 12.613 19.1372 12.3332 18.792 12.3332C18.4468 12.3332 18.167 12.613 18.167 12.9582C18.167 13.3033 18.4468 13.5832 18.792 13.5832Z"
            fill="#737373"/>
    </svg>
  </>
);

export default PrinterFree;
