import React from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import { goToPage } from "../../../redux/app/actions";
import { Page } from "../../../types/Config";
import useModalStyles from "../../../utility/useModalStyles";
import useSelector from "../../../utility/useTypedSelector";
import $PageModal from "./styles";
import SVGButton from "../../SVGButton";
import getTranslator from "../../../utility/getTranslator";
import { DataCyStrings } from "../../../types/DataCyStrings";
import ga from "../../../utility/GAEmitter";
import {
  AnalyticCategories, AnalyticsPage,
  CommonPageActions, getCategoryFromPageOrModalName,
  LoginPageActions
} from "../../../types/Analytics";

const useTranslator = getTranslator("PageModal");

interface PageProps {
  page: Page,
  children: JSX.Element[] | JSX.Element,
  onClose?: Function,
  noHeader?: boolean,
  width?: string,
  isOpen?: boolean,
  dataCyString?: DataCyStrings
}

const PageModal = ({page, children, onClose, noHeader, width, isOpen=true, dataCyString}: PageProps) => {
  const modalStyles = useModalStyles({width: width});
  const dispatch = useDispatch();
  const {t} = useTranslator();
  const {currentPageName, currentModalPageName} = useSelector(store => store.app);

  const leftHeader = page?.leftHeaderLink;

  const exitPageClickedOutsideModal = () => {
    const category = getCategoryFromPageOrModalName(currentPageName, currentModalPageName)
    ga.event({
      category: category,
      action: CommonPageActions.OutsideModal
    })
    onClose ? onClose() : dispatch(goToPage(currentPageName));
  }

  const exitPage = () => {
    if (currentPageName === AnalyticsPage.LoginReturnPortal) {
      //on login page, currentModalPageName is blank
      ga.event({
        category: AnalyticCategories.LoginPage,
        action: LoginPageActions.TranslationGlobeModalClose
      })
    } else {
      const category = getCategoryFromPageOrModalName(currentPageName, currentModalPageName)
      ga.event({
        category: category,
        action: CommonPageActions.CloseModal
      })
    }
    onClose ? onClose() : dispatch(goToPage(currentPageName));
  }

  const backHandler = () => {
    const category = getCategoryFromPageOrModalName(currentPageName, currentModalPageName)
    ga.event({
      category: category,
      action: CommonPageActions.BackArrow
    })
    dispatch(goToPage(leftHeader?.destinationPage));
  }

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      onRequestClose={exitPageClickedOutsideModal}
      style={modalStyles}
    >
      <$PageModal noHeader={noHeader} data-cy={dataCyString}>
        <div className="modal-header">
          <div className="header left">
            {leftHeader &&
            <SVGButton onClick={backHandler} label={t('goBack')} svg={"backArrow"}/>
            }
          </div>
          <div className="header page-title" data-cy={`${dataCyString}-title`}>
            <h1>{page?.title && page.title}</h1>
          </div>
          <div className="header right">
            <SVGButton onClick={exitPage} label={t("closeModal")} svg={"x"} dataCyString={DataCyStrings.closeModalButton}/>
          </div>
        </div>
        {children}
      </$PageModal>
    </Modal>
  )
}

export default PageModal;
