import styled from "styled-components";

// language=SCSS prefix=*{ suffix=}
const $Exchange = styled.div`
  display: flex;
  flex-direction: row;

  .instance-image {
    display: flex;
    width: 300px;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    > img {
      width: 80%;
      height: auto;
      object-fit: contain;
      /* IE11 polyfill */
      font-family: 'object-fit: contain;';
      margin-bottom: 20px;
    }
  }

  .exchange-selector {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow-y: auto;
    color: var(--text-black);

    .instance-name {
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 5px;
      h2 {
        font-size: 16px;
        font-weight: bold;
        margin-block-start: 0;
        margin-block-end: 0;
      }
    }

    .purchase-details {
      font-size: 16px;
      font-weight: 100;
      line-height: 18px;
      color: var(--header-grey);
      margin-bottom: 20px;
    }

    .disclaimer {
      font-size: 12px;
      color: #737373;
      margin-bottom: 8px;
      word-wrap: break-word;
    }

    .exchange-attribute {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      .attribute-name {
        margin-bottom: 8px;
        font-size: 16px;
        height: 22px;
        h3 {
          margin-bottom: 8px;
            font-size: 16px;
            font-weight: 400;
            margin-block-start: 0;
            margin-block-end: 0;
        }
        .attribute-value {
          font-weight: bold;
          margin-left: 5px;
        }
      }
    }
  }

  .submit-exchange-container {
    margin-bottom: 32px;
    flex-grow: 1;
    position: relative
  }

  .submit-exchange-button-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .store-credit-text {
    color: --text-black;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 8px;

    h2 {
      color: --text-black;
      font-size: 15px;
      font-weight: 700;
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }
  @media screen and (max-width: 768px){
    flex-direction: column;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;

    .instance-image {
      width: 100%;
    }
  }

`;

export default $Exchange
