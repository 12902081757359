import React, { useEffect, useState } from "react";

import { ReturnStatusComposite } from "../index";
import getTranslator from "../../../utility/getTranslator";

import { $ReturnShoppingCard } from "./styles";
import { DataCyStrings } from "../../../types/DataCyStrings";
import { SVG } from "../../../components/svg";
import PrimaryButton from "../../../components/Button/PrimaryButton";
import ga from "../../../utility/GAEmitter";
import {
  AnalyticCategories,
  AnalyticsPageRoutes,
  ReturnStartedAndReturnStatusPageActions
} from "../../../types/Analytics";
import useSelector from "../../../utility/useTypedSelector";
import { getAdminMode, getTextColorFromBackgroundBrightness, isMobile } from "../../../utility";
import { ReturnShoppingItem } from "../../../redux/customer/types";
import { ReturnStatus } from "../../../types/ReturnStatus";

export const ReturnShoppingCard = ({
  returnShoppingDiscount,
  returnShoppingInvoiceURL,
  returnShoppingItems,
  returnShoppingProcessingFeeWaived,
  returnStatus
}: ReturnStatusComposite) => {
  const { t } = getTranslator("ReturnShoppingCard")();

  const {
    copies,
    locale,
    colors
  } = useSelector((store) => store.app);
  const retailerName = copies?.retailerName
  const [isAdminMode, setIsAdminMode] = useState<boolean>(getAdminMode());
  const externalLinkColor = colors?.primaryColor ? getTextColorFromBackgroundBrightness(colors.primaryColor) : "white"

  useEffect(() => {
    ga.setDimensions({
      user_properties: {
        admin_mode: isAdminMode,
        retailer_name: retailerName,
        locale: locale
      }
    })
    ga.sendPageDetails(AnalyticsPageRoutes.ReturnStatus, AnalyticCategories.ReturnStatusPage);
  }, []);

  const openInvoiceInNewTab = async () => {
    ga.event({
      category: AnalyticCategories.ReturnStatusPage,
      action: ReturnStartedAndReturnStatusPageActions.ReturnShoppingCheckoutNow
    })
    window.open(returnShoppingInvoiceURL, "_blank");
  };

  const onClickReturnShoppingWaiveReturnProcessingFee = () => {
    ga.event({
      category: AnalyticCategories.ReturnStatusPage,
      action: ReturnStartedAndReturnStatusPageActions.ReturnShoppingWaiveReturnProcessingFee
    })
  }

  const onClickReturnShoppingDiscount = () => {
    ga.event({
      category: AnalyticCategories.ReturnStatusPage,
      action: ReturnStartedAndReturnStatusPageActions.ReturnShoppingDiscount
    })
  }

  const onReturnShoppingThumbnailClick = () => {
    ga.event({
      category: AnalyticCategories.ReturnStatusPage,
      action: ReturnStartedAndReturnStatusPageActions.ReturnShoppingProductImage
    })
  }

  const onReturnShoppingMoreIconClick = () => {
    ga.event({
      category: AnalyticCategories.ReturnStatusPage,
      action: ReturnStartedAndReturnStatusPageActions.ReturnShoppingMoreIcon
    })
  }

  const getImageAltText = (item : ReturnShoppingItem) => {
    if (!!item.title){
      return item.title;
    }

    return item.name;
  }

  const images = returnShoppingItems?.map((item, index) => (
    // blank image URL if image isn't available
    // better than a broken image
    <img
      key={`return-shopping-image-${index}`}
      src={item.image || "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="}
      alt={getImageAltText(item)}
      className="return-shopping-images"
      onClick={onReturnShoppingThumbnailClick}
    />
  ));

  return (
    <$ReturnShoppingCard externalLinkSVGColor={externalLinkColor}>
        <div className="return-shopping-cart">
          <div className={isMobile() ? "complete-your-new-order-mobile" : "complete-your-new-order"}><h2>{t("header")}</h2></div>
        </div>
        {images && (
          <div
            className="return-shopping-image-container"
            data-cy={DataCyStrings.returnShoppingImageContainer}
          >
            {images.slice(0, 3)}
            {images.length > 3 && (
              <div className="more" data-cy={DataCyStrings.moreIcon} onClick={onReturnShoppingMoreIconClick}>
                {"+\n"}
                {t("imageCarouselAdditionalItems")}
              </div>
            )}
          </div>
        )}
        { !!returnShoppingProcessingFeeWaived && returnStatus !== ReturnStatus.complete && returnStatus !== ReturnStatus.partial && (
            <div className="return-shopping-processing-fee" onClick={onClickReturnShoppingWaiveReturnProcessingFee}>
              <div className="return-shopping-icon">
                <SVG name="tag" />
              </div>
              {t("waiveProcessingFee")}
            </div>
          )}
        {!!returnShoppingDiscount && (
          <div className={!!returnShoppingProcessingFeeWaived ? "return-shopping-discount-margin-top-8" : "return-shopping-discount"} onClick={onClickReturnShoppingDiscount}>
            <div className="return-shopping-icon">
              <SVG name="tag" />
            </div>
            {t("discountAmount", { returnShoppingDiscount })}
          </div>
        )}
        <div className={ isMobile() ? "pay-invoice-button-mobile" :"pay-invoice-button" }>
          <PrimaryButton
            dataCyString={DataCyStrings.payInvoiceButton}
            label={t("callToAction")}
            width={isMobile() ? "100%" : "fit-content"}
            onButtonClick={openInvoiceInNewTab}
            children={
              <div className="call-to-action-button-contents">
                <span className="call-to-action-button-text">{t("callToAction")}</span>
                <SVG name="external-link" />
              </div>
            }
          ></PrimaryButton>
        </div>
    </$ReturnShoppingCard>
  );
};
