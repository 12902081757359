import styled from "styled-components";

interface IProductImageProps{
  imageUrls?: string[],
}

const $NavigationCard = styled.div<IProductImageProps>`
  display: flex;
  align-items: ${({ imageUrls }) => ((imageUrls && Array.isArray(imageUrls) && imageUrls.length == 3) ? "normal": "center")};
  font-family: "sofia-pro", sans-serif;
  width: 100%;
  min-height: 88px;
  padding: 16px;
  border: 1px solid var(--border-grey);
  border-radius: 12px;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    @media (min-width: 759px) {
      border: 1px solid var(--text-black);
    }
  }
  .icon {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    margin-right: 16px;
  }

  .title {
    font-weight: 800;
    font-size: 15px;
    color: var(--text-black);

    h2 {
      font-weight: 400;
      font-size: 15px;
      color: var(--text-black);
      margin-block-start: 0;
      margin-block-end: 0;
    }
    .subtext {
      color: var(--header-grey);
      font-size: 14px;
      font-weight: normal;
      white-space: pre-line;
    }
  }

  .arrow {
    margin-left: auto;
  }

  .product-image-container {
    padding-top: 16px;
    display: flex;
  }

  .product-image {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--disabled-grey);
    border-radius: 8px;
    width: 128px;
    height: 128px;
    margin-right: 16px;
    position: relative;
    overflow: hidden;
  }

  @media screen and (max-width: 480px){
    .product-image{
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--disabled-grey);
      border-radius: 8px;
      width: 72px;
      height: 72px;
      margin-right: 8px;
      position: relative;
      overflow: hidden;
    }
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

`;

export default $NavigationCard;
