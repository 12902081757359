/**
 * Use `npm run plop` to add new SVGs
 */
import React from "react";
import { DataCyStrings } from "../../types/DataCyStrings";

import WideLogo from "./WideLogo";
import BackArrow from "./BackArrow";
import Exclamation from "./Exclamation";
import Check from "./Check";
import CheckCircle from "./CheckCircle";
import Information from "./Information";
import One from "./One";
import Two from "./Two";
import Close from "./Close";
import GenericUser from "./GenericUser";
import Search from "./Search";
import ClearSearch from "./ClearSearch";
import Arrow from "./Arrow";
import Play from "./Play";
import Gift from "./Gift";
import X from "./X";
import Refund from "./Refund";
import Exchange from "./Exchange";
import Suggestion from "./Suggestion";
import Success from "./Success";
import Email from "./Email";
import PoweredByText from "./PoweredByText";
import Lock from "./Lock";
import Zoom from "./Zoom";
import Info from "./Info";
import Shop from "./Shop";
import HRLogo from "./HRLogo";
import CardboardBox from "./CardboardBox";
import Printerless from "./Printerless";
import ShippingLabel from "./ShippingLabel";
import Leaf from "./Leaf";
import Tag from "./Tag";
import GiftCard from "./GiftCard";
import Location from "./Location";
import Clock from "./Clock";
import Up from "./Up";
import Down from "./Down";
import QRCode from "./QRCode";
import Printer from "./Printer";
import Globe from "./Globe";
import HRLogoHappyReturns from "./HRLogoHappyReturns";
import HRLogoPaypal from "./HRLogoPaypal";
import GreenCheckIcon from "./GreenCheckIcon";
import Plus from "./Plus";
import Shirt from "./Shirt";
import RightArrow from "./RightArrow";
import Polybag from "./Polybag";
import Tote from "./Tote";
import Decrement from "./Decrement";
import Increment from "./Increment";
import Return from "./Return";
import ShoppingCart from "./ShoppingCart";
import ShoppingCartBlack from "./ShoppingCartBlack";
import InfoBlack from "./InfoBlack";
import ExternalLink from "./ExternalLink";
import DownloadSuccess from "./DownloadSuccess";
import FileCopy from "./FileCopy";
import Calendar from "./Calendar";
import Replace from "./Replace";
import Expired from "./Expired";
import Error from "./Error";
import ReturnistaInfo from "./ReturnistaInfo";
import ReturnistaQrScan from "./ReturnistaQrScan";
import Megaphone from "./Megaphone";
import ReturnistaBarCode from "./ReturnistaBarCode";
import ReturnistaMegaphone from "./ReturnistaMegaphone";
import ReturnistaAddIcon from "./ReturnistaAddIcon";
import ReturnistaReturnCounter from "./ReturnistaReturnCounter";
import ReturnistaWarning from "./ReturnistaWarning";
import ReturnistaCircleCheck from "./ReturnistaCircleCheck";
import ReturnistaCircleError from "./ReturnistaCircleError";
import CircleError from "./CircleError";
import YellowClock from "./YellowClock";
import HRIcon from "./HRIcon";
import Truck from "./Truck";
import EnvelopeCheck from "./EnvelopeCheck";
import EditLocation from "./EditLocation";
import CircleCheckFilled from "./CircleCheckFilled";
import UPSLogo from "./UPSLogo";
import PrinterFree from "./PrinterFree";

export type SVGName =
  | "returnistaCircleError"
  | "returnistaCircleCheck"
  | "returnistaWarning"
  | "returnistaReturnCounter"
  | "returnistaAddIcon"
  | "returnistaMegaphone"
  | "returnistaBarCode"
  | "returnistaQrScan"
  | "returnistaInfo"
  | "wideLogo"
  | "backArrow"
  | "exclamation"
  | "check"
  | "information"
  | "close"
  | "one"
  | "two"
  | "genericUser"
  | "clearSearch"
  | "search"
  | "checkCircle"
  | "arrow"
  | "play"
  | "gift"
  | "x"
  | "store-credit"
  | "gift-card"
  | "refund"
  | "exchange"
  | "suggestion"
  | "success"
  | "email"
  | "poweredByText"
  | "lock"
  | "zoom"
  | "info"
  | "shop"
  | "hr-logo"
  | "cardboard-box"
  | "printerless"
  | "printer-free"
  | "shipping-label"
  | "leaf"
  | "tag"
  | "location"
  | "clock"
  | "up"
  | "down"
  | "qrCode"
  | "printer"
  | "globe"
  | "hr-logo-happyreturns"
  | "hr-logo-paypal"
  | "green-check-icon"
  | "plus"
  | "shirt"
  | "rightArrow"
  | "polybag"
  | "tote"
  | "decrement"
  | "increment"
  | "return"
  | "shopping-cart"
  | "shopping-cart-black"
  | "info-black"
  | "external-link"
  | "download-success"
  | "file-copy"
  | "calendar"
  | "replace"
  | "expired"
  | "error"
  | "megaphone"
  | "circleError"
  | "yellowClock"
  | "hr-icon"
  | "truck"
  | "envelope-check"
  | "edit-location"
  | "circleCheckFilled"
  | "ups-logo";

/**
 * IMPORTANT: new SVGs should added to TS types in params and within the switch case
 * @props iconName {string}
 */
export const SVG = (props: { name: SVGName; [key: string]: any }) => {
  switch (props.name) {
    case "wideLogo":
      return <WideLogo {...props} />;
    case "backArrow":
      return <BackArrow {...props} />;
    case "exclamation":
      return <Exclamation {...props} />;
    case "check":
      return <Check {...props} />;
    case "information":
      return <Information {...props} />;
    case "close":
      return <Close {...props} />;
    case "one":
      return <One {...props} />;
    case "two":
      return <Two {...props} />;
    case "genericUser":
      return <GenericUser {...props} />;
    case "search":
      return <Search {...props} />;
    case "clearSearch":
      return <ClearSearch {...props} />;
    case "checkCircle":
      return <CheckCircle {...props} />;
    case "arrow":
      return <Arrow {...props} />;
    case "play":
      return <Play {...props} />;
    case "gift":
      return <Gift {...props} />;
    case "x":
      return <X {...props} />;
    case "store-credit":
    case "gift-card":
      return <GiftCard {...props} />;
    case "refund":
      return <Refund {...props} />;
    case "exchange":
      return <Exchange {...props} />;
    case "suggestion":
      return <Suggestion {...props} />;
    case "success":
      return <Success {...props} />;
    case "email":
      return <Email {...props} />;
    case "poweredByText":
      return <PoweredByText {...props} />;
    case "lock":
      return <Lock {...props} />;
    case "zoom":
      return <Zoom {...props} />;
    case "info":
      return <Info {...props} />;
    case "returnistaInfo":
      return <ReturnistaInfo {...props} />;
    case "returnistaQrScan":
      return <ReturnistaQrScan {...props} />;
    case "returnistaBarCode":
      return <ReturnistaBarCode {...props} />;
    case "returnistaMegaphone":
      return <ReturnistaMegaphone {...props} />;
    case "returnistaAddIcon":
      return <ReturnistaAddIcon {...props} />;
    case "returnistaReturnCounter":
      return <ReturnistaReturnCounter {...props} />;
    case "returnistaWarning":
      return <ReturnistaWarning {...props} />;
    case "returnistaCircleCheck":
      return <ReturnistaCircleCheck {...props} />;
    case "returnistaCircleError":
      return <ReturnistaCircleError {...props} />;
    case "shop":
      return <Shop {...props} />;
    case "hr-logo":
      return <HRLogo data-cy={DataCyStrings.hrLogoIcon} {...props} />;
    case "cardboard-box":
      return <CardboardBox {...props} />;
    case "printerless":
      return <Printerless {...props} />;
    case "shipping-label":
      return <ShippingLabel {...props} />;
    case "leaf":
      return <Leaf {...props} />;
    case "clock":
      return <Clock {...props} />;
    case "location":
      return <Location {...props} />;
    case "tag":
      return <Tag {...props} />;
    case "up":
      return <Up {...props} />;
    case "down":
      return <Down {...props} />;
    case "qrCode":
      return <QRCode {...props} />;
    case "printer":
      return <Printer {...props} />;
    case "globe":
      return <Globe {...props} />;
    case "hr-logo-happyreturns":
      return <HRLogoHappyReturns {...props} />;
    case "hr-logo-paypal":
      return <HRLogoPaypal {...props} />;
    case "green-check-icon":
      return <GreenCheckIcon {...props} />;
    case "plus":
      return <Plus {...props} />;
    case "shirt":
      return <Shirt data-cy={DataCyStrings.shirtIcon} {...props} />;
    case "rightArrow":
      return <RightArrow data-cy={DataCyStrings.rightArrowIcon} {...props} />;
    case "polybag":
      return <Polybag data-cy={DataCyStrings.polybagIcon} {...props} />;
    case "tote":
      return <Tote data-cy={DataCyStrings.toteIcon} {...props} />;
    case "decrement":
      return <Decrement data-cy={DataCyStrings.decrementIcon} {...props} />;
    case "increment":
      return <Increment data-cy={DataCyStrings.incrementIcon} {...props} />;
    case "return":
      return <Return {...props} />;
    case "shopping-cart":
      return <ShoppingCart {...props} />;
    case "shopping-cart-black":
      return <ShoppingCartBlack {...props} />;
    case "info-black":
      return <InfoBlack {...props} />;
    case "external-link":
      return <ExternalLink {...props} />;
    case "download-success":
      return <DownloadSuccess {...props} />;
    case "file-copy":
      return <FileCopy {...props} />;
    case "calendar":
      return <Calendar {...props} />;
    case "replace":
      return <Replace {...props} />;
    case "expired":
      return <Expired {...props} />;
    case "error":
      return <Error {...props} />;
    case "megaphone":
      return <Megaphone {...props} />;
    case "circleError":
      return <CircleError {...props} />;
    case "yellowClock":
      return <YellowClock {...props} />;
    case "hr-icon":
      return <HRIcon {...props} />;
    case "truck":
      return <Truck {...props} />;
    case "envelope-check":
      return <EnvelopeCheck {...props} />;
    case "edit-location":
      return <EditLocation {...props} />;
    case "circleCheckFilled":
      return <CircleCheckFilled {...props} />;
    case "ups-logo":
      return <UPSLogo {...props} />;
    case "printer-free":
      return <PrinterFree {...props} />;
    default:
      return null;
  }
};
